import {DepositAudit, DepositList} from "@/Api/Money";
import Storage from "@/util/Storage"
import {AwaitDepositStore} from "@/views/Money/AwaitDeposit/Store";

// 待审核列表
export function getDepositList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( DepositList({ userId,token },AwaitDepositStore.getAwaitDepositUpData).then(res=>{
        return res.data
    }) )
}

// 审核
export function setDepositAudit(detailOrderId = "",auditStatus = 0) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( DepositAudit({ userId,token },{ detailOrderId,auditStatus }).then(res=>{
        return res.data
    }) )
}
