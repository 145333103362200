




export const navList = [
    {
        value:"未审批",
        state:3
    },{
        value:"审核中",
        state:2
    },{
        value:"已通过",
        state:0
    },{
        value:"已驳回",
        state:1
    },{
        value:"待金额到达",
        state:4
    },{
        value:"金额未到达",
        state:5
    },
]
